import Vue from "vue";
import VueRouter from "vue-router";
import _ from "lodash";
import store from "@/store";
import moment from "moment";
import multiguard from "vue-router-multiguard";
import routerMockup from "@/router/mockup";
Vue.use(VueRouter);

const removeURLParameter = function (url, parameter) {
  //refer to https://stackoverflow.com/a/26257722
  var urlparts = url.split("?");
  if (urlparts.length >= 2) {
    var prefix = encodeURIComponent(parameter) + "=";
    var pars = urlparts[1].split(/[&;]/g);
    for (var i = pars.length; i-- > 0; ) {
      if (pars[i].lastIndexOf(prefix, 0) !== -1) {
        pars.splice(i, 1);
      }
    }
    url = urlparts[0] + "?" + pars.join("&");
    return url;
  } else {
    return url;
  }
};

// Logic for login gard for routes
const loggedIn = function (to, from, next) {
  var format = "hh:mm:ss";
  var time = moment(),
    startTime = moment("07:00:00", format),
    endTime = moment("23:00:00", format);

  if (time.isBetween(startTime, endTime) || store.state.settings.data.debug) {
    var href = removeURLParameter(window.location.href, "lang");
    var code = href.split("?code=")[1] ? `${href.split("?code=")[1]}` : "";
    //BOC: log out if code is different
    if (code && code != store.state.auth.data.code) {
      store.dispatch("logout");
    }
    //EOC
    if (!store.state.auth.data.Session)
      if (code) {
        next({
          name: "PageAccessMain",
          query: { code: code },
        });
      } else {
        next({
          name: "PageAccessMain",
        });
      }
  } else {
    next({
      name: "PageMainSleeping",
    });
  }
  next();
};

// to redirect if already a student loged in
const loggedInSuccess = function (to, from, next) {
  if (store.state.auth.data.Teacher) {
    next({ name: "PageIslandClassroom" });
  }
  if (store.state.auth.data.Session && store.state.auth.data.Player) {
    next({ name: "PageExploreLanding" });
  }
  next();
};

//Logic for language locale
var pathArray = window.location.pathname.split("/");
var locale = pathArray[1];
if (locale != "" && !(locale == "en" || locale == "zh" || locale == "ms")) {
  window.location.href = window.location.origin;
}

// Logic for enable and disable debug mode
if (new URLSearchParams(window.location.search).has("debug")) {
  let tempSettings;
  if (store.state.settings.data) {
    tempSettings = _.cloneDeep(store.state.settings.data);
    if (new URLSearchParams(window.location.search).get("debug") == 1) {
      tempSettings["debug"] = true;
    } else {
      tempSettings["debug"] = false;
    }
  } else {
    if (new URLSearchParams(window.location.search).get("debug") == 1) {
      tempSettings = {
        debug: true,
      };
    } else {
      tempSettings = {
        debug: false,
      };
    }
  }
  store.commit("updateSettings", tempSettings);
}

// Logic for enable and disable game cache mode
if (new URLSearchParams(window.location.search).has("cache")) {
  let tempSettings;
  if (store.state.settings.data) {
    tempSettings = _.cloneDeep(store.state.settings.data);
    if (new URLSearchParams(window.location.search).get("cache") == 1) {
      tempSettings["cache"] = true;
    } else {
      tempSettings["cache"] = false;
    }
  } else {
    if (new URLSearchParams(window.location.search).get("cache") == 1) {
      tempSettings = {
        cache: true,
      };
    } else {
      tempSettings = {
        cache: false,
      };
    }
  }
  store.commit("updateSettings", tempSettings);
}

// all route logics
const routes = [
  // Routes for main pages
  {
    path: "/",
    component: () =>
      import(/* webpackChunkName: "layout-main" */ "@/layouts/LayoutMain.vue"),
    children: [
      {
        path: "",
        name: "PageMainLanding",
        component: () =>
          import(
            /* webpackChunkName: "main-landing" */ "@/views/main/PageMainLanding.vue"
          ),
      },
      {
        path: "logout",
        name: "PageMainLogout",
        component: () =>
          import(
            /* webpackChunkName: "main-logout" */ "@/views/main/PageMainLogout.vue"
          ),
      },
    ],
  },

  routerMockup,

  // Routes for testing
  {
    path: "/testing",
    component: () =>
      import(
        /* webpackChunkName: "layout-main" */ "@/layouts/LayoutTesting.vue"
      ),
    children: [
      {
        path: "game/play",
        name: "GameTesting",
        beforeEnter: multiguard([loggedIn]),
        component: () =>
          import(
            /* webpackChunkName: "game-testing" */ "@/views/testing/GameTestingDino.vue"
          ),
        meta: {
          title: "Game Testing",
        },
      },
      {
        path: "dino-discover",
        name: "DinoTesting",
        beforeEnter: multiguard([loggedIn]),
        component: () =>
          import(
            /* webpackChunkName: "game-testing" */ "@/views/testing/GameTestingDino.vue"
          ),
        meta: {
          title: "Dino Discover",
        },
      },
      // {
      //   path: "game/play-v2",
      //   name: "GameTestingV2",
      //   beforeEnter: multiguard([loggedIn]),
      //   component: () =>
      //     import(
      //       /* webpackChunkName: "game-testing-v2" */ "@/views/testing/GameTestingV2.vue"
      //     ),
      //   meta: {
      //     title: "Game Testing V2",
      //   },
      // },
      {
        path: "tutorial/play",
        name: "GameTutorialTesting",
        beforeEnter: multiguard([loggedIn]),
        component: () =>
          import(
            /* webpackChunkName: "game-tutorial-testing" */ "@/views/testing/GameTutorialTesting.vue"
          ),
        meta: {
          title: "Game Testing",
        },
      },
      {
        path: "game/score",
        name: "GameTestingScore",
        beforeEnter: multiguard([loggedIn]),
        component: () =>
          import(
            /* webpackChunkName: "game-score-testing" */ "@/views/testing/GameTestingScore.vue"
          ),
        meta: {
          title: "Game Testing",
        },
      },
      {
        path: "dailyGame",
        name: "GameTestingDailyGame",
        beforeEnter: multiguard([loggedIn]),
        component: () =>
          import(
            /* webpackChunkName: "daily-game" */ "@/views/testing/GameTestingDailyGame.vue"
          ),
        meta: {
          title: "Game Testing",
        },
      },
      {
        path: "payment/checkout",
        name: "GameTestingCheckout",
        beforeEnter: multiguard([loggedIn]),
        component: () =>
          import(
            /* webpackChunkName: "game-testing-checkout" */ "@/views/testing/GameTestingCheckout.vue"
          ),
        meta: {
          title: "Checkout",
        },
      },
      {
        path: "game/workspace",
        name: "GameTestingGameWorkspace",
        beforeEnter: multiguard([loggedIn]),
        component: () =>
          import(
            /* webpackChunkName: "game-testing-workspace" */ "@/views/testing/GameTestingGameWorkspace.vue"
          ),
        meta: {
          title: "Workspace",
        },
      },
      {
        path: "abc",
        name: "PageTestingExploreABC",
        beforeEnter: multiguard([loggedIn]),
        component: () =>
          import(
            /* webpackChunkName: "hamochi-abc" */ "@/views/testing/PageTestingExploreABC.vue"
          ),
        meta: {
          title: "ABC",
        },
      },
      {
        path: "abc-map",
        name: "PageTestingExploreABCMap",
        beforeEnter: multiguard([loggedIn]),
        component: () =>
          import(
            /* webpackChunkName: "hamochi-abc" */ "@/views/testing/PageTestingExploreABCMap.vue"
          ),
        meta: {
          title: "ABC",
        },
      },
    ],
  },
  {
    path: "/testing",
    component: () =>
      import(
        /* webpackChunkName: "layout-main" */ "@/layouts/LayoutTestingDailyGame.vue"
      ),
    children: [
      {
        path: "dailyGame/play-v2",
        name: "GameTestingDailyGameV2",
        beforeEnter: multiguard([loggedIn]),
        component: () =>
          import(
            /* webpackChunkName: "game-testing" */ "@/views/testing/GameTestingDailyGameV2.vue"
          ),
        meta: {
          title: "Daily Game V2",
        },
      },
    ],
  },
  // Routes for testing
  {
    path: "/testing",
    component: () =>
      import(
        /* webpackChunkName: "layout-main" */ "@/layouts/LayoutExploreEvent.vue"
      ),
    children: [
      {
        path: "dailyGame/play",
        name: "GameTestingDailyGamePlay",
        beforeEnter: multiguard([loggedIn]),
        component: () =>
          import(
            /* webpackChunkName: "daily-game-play" */ "@/views/testing/GameTestingDailyGamePlay.vue"
          ),
        meta: {
          title: "Game Testing",
        },
      },
      {
        path: "dailyGame/landing",
        name: "PageTestingDailyGameLanding",
        beforeEnter: multiguard([loggedIn]),
        component: () =>
          import(
            /* webpackChunkName: "daily-game-landing" */ "@/views/testing/PageTestingDailyGameLanding.vue"
          ),
        meta: {
          title: "Game Testing",
        },
      },
    ],
  },

  // Route for sleeping
  {
    path: "/goodnight",
    name: "PageMainSleeping",
    component: () =>
      import(
        /* webpackChunkName: "main-sleeping" */ "@/views/main/PageMainSleeping.vue"
      ),
    meta: {
      title: "Goodnight",
    },
  },

  // Routes for access pages
  {
    path: "/access",
    component: () =>
      import(
        /* webpackChunkName: "layout-access" */ "@/layouts/LayoutAccess.vue"
      ),
    beforeEnter: multiguard([loggedInSuccess]),
    children: [
      {
        path: "",
        name: "PageAccessMain",
        component: () =>
          import(
            /* webpackChunkName: "access-main" */ "@/views/access/PageAccessMain.vue"
          ),
        meta: {
          title: "Access",
        },
      },
      {
        path: "school",
        name: "PageAccessSchool",
        component: () =>
          import(
            /* webpackChunkName: "access-school" */ "@/views/access/PageAccessSchool.vue"
          ),
        meta: {
          title: "School",
        },
      },
      {
        path: "family",
        name: "PageAccessFamily",
        component: () =>
          import(
            /* webpackChunkName: "access-family" */ "@/views/access/PageAccessFamily.vue"
          ),
        meta: {
          title: "Family",
        },
      },
      {
        path: "classroom",
        name: "PageAccessClassroom",
        component: () =>
          import(
            /* webpackChunkName: "access-classroom" */ "@/views/access/PageAccessClassroom.vue"
          ),
        meta: {
          title: "Classroom",
        },
      },
      {
        path: "classroom/register",
        name: "PageAccessClassroomRegister",
        component: () =>
          import(
            /* webpackChunkName: "access-classroom-register" */ "@/views/access/PageAccessClassroomRegister.vue"
          ),
        meta: {
          title: "Register",
        },
      },
      {
        path: "onboarding/avatar",
        name: "PageAccessOnboardingAvatar",
        component: () =>
          import(
            /* webpackChunkName: "onboarding-avatar" */ "@/views/access/PageAccessOnboardingAvatar.vue"
          ),
        meta: {
          title: "Onboarding Avatar",
        },
      },

      {
        path: "password",
        name: "PageAccessPassword",
        component: () =>
          import(
            /* webpackChunkName: "access-password" */ "@/views/access/PageAccessPassword.vue"
          ),
        meta: {
          title: "Access Password",
        },
      },
      {
        path: "pin",
        name: "PageAccessPin",
        component: () =>
          import(
            /* webpackChunkName: "access-pin" */ "@/views/access/PageAccessPin.vue"
          ),
        meta: {
          title: "Access Pin",
        },
      },
      {
        path: "onboarding/password",
        name: "PageAccessOnboardingPassword",
        component: () =>
          import(
            /* webpackChunkName: "onboarding-password" */ "@/views/access/PageAccessOnboardingPassword.vue"
          ),
        meta: {
          title: "Onboarding Password",
        },
      },
    ],
  },

  // Route for explorer landing page
  {
    path: "/landing",
    name: "PageExploreLanding",
    beforeEnter: multiguard([loggedIn]),
    component: () =>
      import(
        /* webpackChunkName: "explore-landing" */ "@/views/Explore/PageExploreLanding.vue"
      ),
    meta: {
      title: "Explore",
    },
  },

  // Route for explorer landing page
  {
    path: "/explore/landing",
    name: "PageExploreLanding-Dev",
    beforeEnter: multiguard([loggedIn]),
    component: () =>
      import(
        /* webpackChunkName: "explore-landing" */ "@/views/Explore/PageExploreLanding-Dev.vue"
      ),
    meta: {
      title: "Explore-Dev",
    },
  },

  {
    path: "/explore/:id",
    name: "PageExploreMiniGames",
    beforeEnter: multiguard([loggedIn]),
    component: () =>
      import(
        /* webpackChunkName: "explore-landing" */ "@/views/Explore/PageExploreMiniGames.vue"
      ),
    meta: {
      title: "ExploreMiniGames",
    },
  },

  {
    path: "/explore/:id/:game",
    name: "PageMiniGamesStart",
    beforeEnter: multiguard([loggedIn]),
    component: () =>
      import(
        /* webpackChunkName: "explore-minigame-start" */ "@/views/Explore/MiniGames/PageMiniGamesStart.vue"
      ),
    meta: {
      title: "MiniGames",
    },
  },

  {
    path: "/explore/:id/:game/play",
    name: "PageMiniGamesPlay",
    beforeEnter: multiguard([loggedIn]),
    component: () =>
      import(
        /* webpackChunkName: "explore-minigame-play" */ "@/views/Explore/MiniGames/PageMiniGamesPlay.vue"
      ),
    meta: {
      title: "ExploreMiniGames",
    },
  },

  {
    path: "/explore/:id/:game/score",
    name: "PageMiniGamesScore",
    beforeEnter: multiguard([loggedIn]),
    component: () =>
      import(
        /* webpackChunkName: "explore-minigame-score" */ "@/views/Explore/MiniGames/PageMiniGamesScore.vue"
      ),
    meta: {
      title: "MiniGames",
    },
  },

  // Route for explorer Series page
  {
    path: "/series",
    name: "PageExploreSeries",
    beforeEnter: multiguard([loggedIn]),
    component: () =>
      import(
        /* webpackChunkName: "explore-landing" */ "@/views/Explore/PageExploreSeries.vue"
      ),
    meta: {
      title: "Series",
    },
  },

  // Route for explorer wardrobe
  {
    path: "/wardrobe",
    name: "PageHomeWardrobe",
    beforeEnter: multiguard([loggedIn]),
    component: () =>
      import(
        /* webpackChunkName: "explore-wardrobe" */ "@/views/Explore/Home/PageHomeWardrobe.vue"
      ),
    meta: {
      title: "Wardrobe",
    },
  },

  // Route for explorer shop
  {
    path: "/shop",
    name: "PageHomeShop",
    beforeEnter: multiguard([loggedIn]),
    component: () =>
      import(
        /* webpackChunkName: "explore-shop" */ "@/views/Explore/Home/PageHomeShop.vue"
      ),
    meta: {
      title: "Shop",
    },
  },

  // Route for explorer locksmith
  {
    path: "/locksmith",
    name: "PageHomeLocksmith",
    beforeEnter: multiguard([loggedIn]),
    component: () =>
      import(
        /* webpackChunkName: "explore-locksmith" */ "@/views/Explore/Home/PageHomeLocksmith.vue"
      ),
    meta: {
      title: "Locksmith",
    },
  },

  // Route for explorer gallery
  {
    path: "/gallery",
    name: "PageHomeGallery",
    beforeEnter: multiguard([loggedIn]),
    component: () =>
      import(
        /* webpackChunkName: "explore-gallery" */ "@/views/Explore/Home/PageHomeGallery.vue"
      ),
    meta: {
      title: "Gallery",
    },
  },
  // Route for explorer studio
  {
    path: "/studio",
    name: "PageHomeStudio",
    beforeEnter: multiguard([loggedIn]),
    component: () =>
      import(
        /* webpackChunkName: "explore-studio" */ "@/views/Explore/Home/PageHomeStudio.vue"
      ),
    meta: {
      title: "Studio",
    },
  },

  {
    path: "/alldrawings",
    name: "PageHomeArt",
    beforeEnter: multiguard([loggedIn]),
    component: () =>
      import(
        /* webpackChunkName: "explore-drawing" */ "@/views/Explore/Home/PageHomeArt.vue"
      ),
    meta: {
      title: "All Awarded Drawings",
    },
  },

  // Route for home page
  {
    path: "/menu",
    name: "PageExploreMenu",
    beforeEnter: multiguard([loggedIn]),
    component: () =>
      import(
        /* webpackChunkName: "explore-home" */ "@/views/Explore/PageExploreMenu.vue"
      ),
    meta: {
      title: "Menu",
    },
  },

  // Route for ABC
  {
    path: "/ABC",
    name: "PageABCBooks",
    beforeEnter: multiguard([loggedIn]),
    component: () =>
      import(
        /* webpackChunkName: "ABC-books" */ "@/views/Explore/ABC/PageABCBooks.vue"
      ),
    meta: {
      title: "ABC",
    },
  },

  {
    path: "/ABC/:bookKey",
    name: "PageABCBookCover",
    beforeEnter: multiguard([loggedIn]),
    component: () =>
      import(
        /* webpackChunkName: "ABC-books-cover" */ "@/views/Explore/ABC/PageABCBookCover.vue"
      ),
    meta: {
      title: "ABC",
    },
  },

  {
    path: "/ABC/:bookKey/chapters",
    name: "PageABCBookChapters",
    beforeEnter: multiguard([loggedIn]),
    component: () =>
      import(
        /* webpackChunkName: "ABC-books-chapter" */ "@/views/Explore/ABC/PageABCBookChapters.vue"
      ),
    meta: {
      title: "ABC",
    },
  },

  {
    path: "/ABC/:bookKey/chapters/:chapterKey",
    name: "PageABCBookReader",
    beforeEnter: multiguard([loggedIn]),
    component: () =>
      import(
        /* webpackChunkName: "ABC-books-reader" */ "@/views/Explore/ABC/PageABCBookReader.vue"
      ),
    meta: {
      title: "ABC",
    },
  },

  {
    path: "/ABC/:bookKey/chapters/:chapterKey/failed",
    name: "PageABCBookFailed",
    beforeEnter: multiguard([loggedIn]),
    component: () =>
      import(
        /* webpackChunkName: "ABC-books-failed" */ "@/views/Explore/ABC/PageABCBookFailed.vue"
      ),
    meta: {
      title: "ABC",
    },
  },

  {
    path: "/ABC/:bookKey/chapters/:chapterKey/completed",
    name: "PageABCBookCompleted",
    beforeEnter: multiguard([loggedIn]),
    component: () =>
      import(
        /* webpackChunkName: "ABC-books-score" */ "@/views/Explore/ABC/PageABCBookCompleted.vue"
      ),
    meta: {
      title: "ABC",
    },
  },

  // Route for collections
  {
    path: "/collections",
    name: "PageExploreCollections",
    beforeEnter: multiguard([loggedIn]),
    component: () =>
      import(
        /* webpackChunkName: "explore-collections" */ "@/views/Explore/Home/PageHomeCollections.vue"
      ),
    meta: {
      title: "Collections",
    },
  },

  {
    path: "/collections/:type",
    name: "PageCollection",
    beforeEnter: multiguard([loggedIn]),
    component: () =>
      import(
        /* webpackChunkName: "collection" */ "@/views/Explore/Collections/PageCollection.vue"
      ),
    meta: {
      title: "Collections",
    },
  },

  // Route for mail page
  {
    path: "/mail",
    name: "PageExploreMail",
    beforeEnter: multiguard([loggedIn]),
    component: () =>
      import(
        /* webpackChunkName: "mail-all" */ "@/views/Explore/Mail/PageMailAll.vue"
      ),
    meta: {
      title: "Mail",
    },
  },

  // Route for mail page
  {
    path: "/mail/:id",
    name: "PageExploreMailDetail",
    beforeEnter: multiguard([loggedIn]),
    component: () =>
      import(
        /* webpackChunkName: "mail-detail" */ "@/views/Explore/Mail/PageMailDetail.vue"
      ),
    meta: {
      title: "Mail",
    },
  },

  // Route for daily game page
  {
    path: "/dailygame",
    name: "PageExploreDailyGame",
    beforeEnter: multiguard([loggedIn]),
    component: () =>
      import(
        /* webpackChunkName: "daily-game" */ "@/views/Explore/DailyGame/PageDailyGame.vue"
      ),
    meta: {
      title: "Daily Game",
    },
  },
  {
    path: "/dailygame/tile",
    name: "PageExploreDailyGameDetails",
    beforeEnter: multiguard([loggedIn]),
    component: () =>
      import(
        /* webpackChunkName: "daily-game-tile" */ "@/views/Explore/DailyGame/PageDailyGameDetails.vue"
      ),
    meta: {
      title: "Daily Game",
    },
  },
  {
    path: "/dailygame/play",
    name: "PageExploreDailyGamePlay",
    beforeEnter: multiguard([loggedIn]),
    component: () =>
      import(
        /* webpackChunkName: "daily-game-play" */ "@/views/Explore/DailyGame/PageDailyGamePlay.vue"
      ),
    meta: {
      title: "Daily Game",
    },
  },
  {
    path: "/dailygame/score",
    name: "PageExploreDailyGameScore",
    beforeEnter: multiguard([loggedIn]),
    component: () =>
      import(
        /* webpackChunkName: "daily-game-score" */ "@/views/Explore/DailyGame/PageDailyGameScore.vue"
      ),
    meta: {
      title: "Daily Game",
    },
  },

  // Routes for explorer Event pages
  {
    path: "/event/:code",
    component: () =>
      import(
        /* webpackChunkName: "layout-event" */ "@/layouts/LayoutExploreEvent.vue"
      ),
    beforeEnter: multiguard([loggedIn]),
    children: [
      {
        path: "/",
        redirect: "/:code/event/levels",
        component: () =>
          import(
            /* webpackChunkName: "layout-explore-event" */ "@/views/Explore/PageExploreEvent.vue"
          ),
        beforeEnter: multiguard([loggedIn]),
        children: [
          {
            path: "levels",
            name: "PageEventLevels",
            component: () =>
              import(
                /* webpackChunkName: "explore-event-levels" */ "@/views/Explore/Event/PageEventLevels.vue"
              ),
            meta: {
              title: "Levels",
            },
          },
          {
            path: "info",
            name: "PageEventInfo",
            component: () =>
              import(
                /* webpackChunkName: "explore-event-info" */ "@/views/Explore/Event/PageEventInfo.vue"
              ),
            meta: {
              title: "Info",
            },
          },
          {
            path: "rank",
            name: "PageEventRank",
            component: () =>
              import(
                /* webpackChunkName: "explore-event-rank" */ "@/views/Explore/Event/PageEventRank.vue"
              ),
            meta: {
              title: "Rank",
            },
          },
          {
            path: "school",
            name: "PageEventSchool",
            component: () =>
              import(
                /* webpackChunkName: "explore-event-School" */ "@/views/Explore/Event/PageEventSchool.vue"
              ),
            meta: {
              title: "School",
            },
          },
        ],
      },

      // Route for explorer event detail page
      {
        path: "rank/detail",
        name: "PageEventRankDetail",
        beforeEnter: multiguard([loggedIn]),
        component: () =>
          import(
            /* webpackChunkName: "explore-event-rank-detail" */ "@/views/Explore/Event/PageEventRankDetail.vue"
          ),
        meta: {
          title: "Rank",
        },
      },

      {
        path: "school/select",
        name: "PageEventSchoolSelect",
        beforeEnter: multiguard([loggedIn]),
        component: () =>
          import(
            /* webpackChunkName: "explore-event-school-select" */ "@/views/Explore/Event/PageEventSchoolSelect.vue"
          ),
        meta: {
          title: "School",
        },
      },

      // Route for explorer event detail page
      {
        path: "gift/sent",
        name: "PageEventGiftSent",
        beforeEnter: multiguard([loggedIn]),
        component: () =>
          import(
            /* webpackChunkName: "explore-event-gift" */ "@/views/Explore/Event/PageEventGiftSent.vue"
          ),
        meta: {
          title: "Gift",
        },
      },

      // Route for explorer event detail page
      {
        path: "gift/form",
        name: "PageEventGiftForm",
        beforeEnter: multiguard([loggedIn]),
        component: () =>
          import(
            /* webpackChunkName: "explore-event-gift" */ "@/views/Explore/Event/PageEventGiftForm.vue"
          ),
        meta: {
          title: "Gift",
        },
      },

      // Route for explorer event detail page
      {
        path: "gift/delivered",
        name: "PageEventGiftDelivered",
        beforeEnter: multiguard([loggedIn]),
        component: () =>
          import(
            /* webpackChunkName: "explore-event-gift" */ "@/views/Explore/Event/PageEventGiftDelivered.vue"
          ),
        meta: {
          title: "Gift",
        },
      },

      // Route for explorer level detail page
      {
        path: "levels/:id",
        name: "PageEventLevelDetail",
        beforeEnter: multiguard([loggedIn]),
        component: () =>
          import(
            /* webpackChunkName: "explore-event-level-detail" */ "@/views/Explore/Event/PageEventLevelDetail.vue"
          ),
        meta: {
          title: "Level",
        },
      },

      {
        path: "levels-skip-complete",
        name: "PageEventLevelComplete",
        beforeEnter: multiguard([loggedIn]),
        component: () =>
          import(
            /* webpackChunkName: "explore-event-level-skip" */ "@/views/Explore/Event/PageEventLevelComplete.vue"
          ),
        meta: {
          title: "Level",
        },
      },

      // Route for explorer game page
      {
        path: "levels/:id/play",
        name: "PageEventGame",
        beforeEnter: multiguard([loggedIn]),
        component: () =>
          import(
            /* webpackChunkName: "explore-event-game" */ "@/views/Explore/Event/PageEventGame.vue"
          ),
        meta: {
          title: "Play",
        },
      },

      {
        path: "levels/:id/watch",
        name: "PageEventLevelYoutube",
        beforeEnter: multiguard([loggedIn]),
        component: () =>
          import(
            /* webpackChunkName: "explore-event-watch" */ "@/views/Explore/Event/PageEventLevelYoutube.vue"
          ),
        meta: {
          title: "Watch",
        },
      },


      {
        path: "tutorial",
        name: "PageEventGameTutorial",
        beforeEnter: multiguard([loggedIn]),
        component: () =>
          import(
            /* webpackChunkName: "explore-event-game-tut" */ "@/views/Explore/Event/PageEventGameTutorial.vue"
          ),
        meta: {
          title: "Tutorial",
        },
      },
      {
        path: "tutorial/completed",
        name: "PageEventGameTutorialCompleted",
        beforeEnter: multiguard([loggedIn]),
        component: () =>
          import(
            /* webpackChunkName: "explore-event-game-tut-completed" */ "@/views/Explore/Event/PageEventGameTutorialCompleted.vue"
          ),
        meta: {
          title: "Tutorial",
        },
      },

      // Route for explorer game page
      {
        path: "levels/:id/score",
        name: "PageEventGameScore",
        beforeEnter: multiguard([loggedIn]),
        component: () =>
          import(
            /* webpackChunkName: "explore-event-game" */ "@/views/Explore/Event/PageEventGameScore.vue"
          ),
        meta: {
          title: "Score",
        },
      },
    ],
  },

  // Error Pages
  {
    path: "",
    component: () =>
      import(/* webpackChunkName: "layout-main" */ "@/layouts/LayoutMain.vue"),
    children: [
      {
        path: "system/connection",
        name: "PageSystemConnection",
        component: () =>
          import(
            /* webpackChunkName: "page-system-connection" */ "@/views/system/PageSystemConnection.vue"
          ),
      },
      {
        path: "system/fix",
        name: "PageSystemFix",
        component: () =>
          import(
            /* webpackChunkName: "page-system-fix" */ "@/views/system/PageSystemFix.vue"
          ),
      },
      {
        path: "error/expired",
        name: "PageErrorSessionExpired",
        component: () =>
          import(
            /* webpackChunkName: "main-expired" */ "@/views/error/PageErrorSessionExpired.vue"
          ),
      },
      {
        path: "/:catchAll(.*)",
        name: "PageErrorNotFound",
        component: () =>
          import(
            /* webpackChunkName: "main-not-found" */ "@/views/error/PageErrorNotFound.vue"
          ),
      },
    ],
  },

  // Archive Routed
  // // Routes for Islands
  // {
  //   path: "/island",
  //   name: "PageIsland",
  //   redirect: "/island/town",
  //   component: () =>
  //     import(
  //       /* webpackChunkName: "layout-island" */ "@/layouts/LayoutIsland.vue"
  //     ),
  //   beforeEnter: multiguard([loggedIn]),
  //   children: [
  //     {
  //       path: "home",
  //       name: "PageIslandHome",
  //       component: () =>
  //         import(
  //           /* webpackChunkName: "island-home" */ "@/views/island/PageIslandHome.vue"
  //         ),
  //       meta: {
  //         title: "Home",
  //       },
  //     },
  //     {
  //       path: "town",
  //       name: "PageIslandTown",
  //       component: () =>
  //         import(
  //           /* webpackChunkName: "island-town" */ "@/views/island/PageIslandTown.vue"
  //         ),
  //       meta: {
  //         title: "Town",
  //       },
  //     },
  //     {
  //       path: "classroom",
  //       name: "PageIslandClassroom",
  //       component: () =>
  //         import(
  //           /* webpackChunkName: "island-classroom" */ "@/views/island/PageIslandClassroom.vue"
  //         ),
  //       meta: {
  //         title: "Classroom",
  //       },
  //     },
  //     {
  //       path: "playground",
  //       name: "PageIslandPlayground",
  //       component: () =>
  //         import(
  //           /* webpackChunkName: "island-playground" */ "@/views/island/PageIslandPlayground.vue"
  //         ),
  //       meta: {
  //         title: "Playground",
  //       },
  //     },
  //   ],
  // },
  // // Routes for Buildings (Entrance pages)
  // // Event Building
  // {
  //   path: "/island/town/events",
  //   name: "PageBuildingEvents",
  //   beforeEnter: multiguard([loggedIn]),
  //   component: () =>
  //     import(
  //       /* webpackChunkName: "building-events" */ "@/views/buildings/PageBuildingEvents.vue"
  //     ),
  //   meta: {
  //     title: "Events",
  //   },
  // },
  // // Energy Building
  // {
  //   path: "/island/town/energy",
  //   name: "PageBuildingEnergy",
  //   beforeEnter: multiguard([loggedIn]),
  //   component: () =>
  //     import(
  //       /* webpackChunkName: "building-energy" */ "@/views/buildings/PageBuildingEnergy.vue"
  //     ),
  //   meta: {
  //     title: "Energy",
  //   },
  // },
  // // Shop building
  // {
  //   path: "/island/town/shop",
  //   name: "PageBuildingShop",
  //   beforeEnter: multiguard([loggedIn]),
  //   component: () =>
  //     import(
  //       /* webpackChunkName: "building-shop" */ "@/views/buildings/PageBuildingShop.vue"
  //     ),
  //   meta: {
  //     title: "Pick Shop",
  //   },
  // },
  // // Lucky Egg Building
  // {
  //   path: "/island/town/lucky-egg",
  //   name: "PageBuildingLuckyEggShop",
  //   beforeEnter: multiguard([loggedIn]),
  //   component: () =>
  //     import(
  //       /* webpackChunkName: "building-luckyegg" */ "@/views/buildings/PageBuildingLuckyEggShop.vue"
  //     ),
  //   meta: {
  //     title: "Lucky Egg",
  //   },
  // },
  // // BugCatcher Building
  // {
  //   path: "/island/playground/bug-catcher",
  //   name: "PageBuildingBugCatcher",
  //   beforeEnter: multiguard([loggedIn]),
  //   component: () =>
  //     import(
  //       /* webpackChunkName: "building-bug-catcher" */ "@/views/buildings/PageBuildingBugCatcher.vue"
  //     ),
  //   meta: {
  //     title: "Bug Catcher",
  //   },
  // },
  // // CardMatching Building
  // {
  //   path: "/island/playground/card-matching",
  //   name: "PageBuildingCardMatching",
  //   beforeEnter: multiguard([loggedIn]),
  //   component: () =>
  //     import(
  //       /* webpackChunkName: "building-card-matching" */ "@/views/buildings/PageBuildingCardMatching.vue"
  //     ),
  //   meta: {
  //     title: "Card Matching",
  //   },
  // },
  // // Report Bulding
  // {
  //   path: "/island/classroom/report",
  //   name: "PageBuildingReport",
  //   component: () =>
  //     import(
  //       /* webpackChunkName: "building-report" */ "@/views/buildings/PageBuildingReport.vue"
  //     ),
  //   beforeEnter: multiguard([loggedIn]),
  //   meta: {
  //     title: "Report",
  //   },
  // },
  // // School Bulding
  // {
  //   path: "/island/classroom/school",
  //   name: "PageBuildingSchool",
  //   component: () =>
  //     import(
  //       /* webpackChunkName: "building-school" */ "@/views/buildings/PageBuildingSchool.vue"
  //     ),
  //   beforeEnter: multiguard([loggedIn]),
  //   meta: {
  //     title: "School",
  //   },
  // },
  // // Mission Bulding
  // {
  //   path: "/island/classroom/homework",
  //   name: "PageBuildingMission",
  //   component: () =>
  //     import(
  //       /* webpackChunkName: "building-mission" */ "@/views/buildings/PageBuildingMission.vue"
  //     ),
  //   beforeEnter: multiguard([loggedIn]),
  //   meta: {
  //     title: "Homework",
  //   },
  // },
  // // Garden Building
  // {
  //   path: "/island/home/garden",
  //   name: "PageBuildingGarden",
  //   beforeEnter: multiguard([loggedIn]),
  //   component: () =>
  //     import(
  //       /* webpackChunkName: "building-garden" */ "@/views/buildings/PageBuildingGarden.vue"
  //     ),
  //   meta: {
  //     title: "Garden",
  //   },
  // },
  // // House Building
  // {
  //   path: "/island/home/house",
  //   name: "PageBuildingHouse",
  //   component: () =>
  //     import(
  //       /* webpackChunkName: "building-house" */ "@/views/buildings/PageBuildingHouse.vue"
  //     ),
  //   meta: {
  //     title: "House",
  //   },
  //   beforeEnter: multiguard([loggedIn]),
  // },
  // // Detail pages of buildings
  // // Mission Building Pages
  // {
  //   path: "/island/classroom/homework",
  //   beforeEnter: multiguard([loggedIn]),
  //   component: () =>
  //     import(
  //       /* webpackChunkName: "layout-mission" */ "@/layouts/LayoutMission.vue"
  //     ),
  //   children: [
  //     {
  //       path: "details",
  //       name: "PageMissionDetails",
  //       component: () =>
  //         import(
  //           /* webpackChunkName: "mission-details" */ "@/views/mission/PageMissionDetails.vue"
  //         ),
  //       meta: {
  //         title: "Homework Details",
  //       },
  //     },
  //     {
  //       path: "question",
  //       name: "PageMissionQuestions",
  //       component: () =>
  //         import(
  //           /* webpackChunkName: "mission-questions" */ "@/views/mission/PageMissionQuestions.vue"
  //         ),
  //       meta: {
  //         title: "Homework Question",
  //       },
  //     },
  //     {
  //       path: "new",
  //       name: "PageMissionAdd",
  //       component: () =>
  //         import(
  //           /* webpackChunkName: "mission-add" */ "@/views/mission/PageMissionAdd.vue"
  //         ),
  //       meta: {
  //         title: "Homework Add",
  //       },
  //     },
  //     {
  //       path: "performance",
  //       name: "PageMissionPerformance",
  //       component: () =>
  //         import(
  //           /* webpackChunkName: "mission-performance" */ "@/views/mission/PageMissionPerformance.vue"
  //         ),
  //       meta: {
  //         title: "Student Performance",
  //       },
  //     },

  //     {
  //       path: "edit",
  //       name: "PageMissionEdit",
  //       component: () =>
  //         import(
  //           /* webpackChunkName: "mission-edit" */ "@/views/mission/PageMissionEdit.vue"
  //         ),
  //       meta: {
  //         title: "Homework Edit",
  //       },
  //     },
  //   ],
  // },
  // // Report Building Pages
  // {
  //   path: "/island/classroom/report",
  //   beforeEnter: multiguard([loggedIn]),
  //   component: () =>
  //     import(
  //       /* webpackChunkName: "layout-report" */ "@/layouts/LayoutReport.vue"
  //     ),
  //   children: [
  //     {
  //       path: "view",
  //       name: "PageReportView",
  //       component: () =>
  //         import(
  //           /* webpackChunkName: "report-view" */ "@/views/report/PageReportView.vue"
  //         ),
  //       meta: {
  //         title: "Report",
  //       },
  //     },
  //   ],
  // },
  // // Bug Catcher Building Pages
  // {
  //   path: "/island/playground/bug-catcher",
  //   beforeEnter: multiguard([loggedIn]),
  //   component: () =>
  //     import(
  //       /* webpackChunkName: "layout-bug-catch" */ "@/layouts/LayoutBugcatch.vue"
  //     ),
  //   children: [
  //     {
  //       path: "details",
  //       name: "PageBugCatcherDetails",
  //       component: () =>
  //         import(
  //           /* webpackChunkName: "bug-catch-detail" */ "@/views/miniGames/bugCatcher/PageBugCatcherDetails.vue"
  //         ),
  //       meta: {
  //         title: "Bug Catcher",
  //       },
  //     },
  //     {
  //       path: "play",
  //       name: "PageBugCatcherHome",
  //       component: () =>
  //         import(
  //           /* webpackChunkName: "bug-catch-home" */ "@/views/miniGames/bugCatcher/PageBugCatcherHome.vue"
  //         ),
  //       meta: {
  //         title: "Bug Catcher",
  //       },
  //     },
  //     {
  //       path: "collection",
  //       name: "PageBugCatcherBugCollection",
  //       component: () =>
  //         import(
  //           /* webpackChunkName: "bug-catch-collection" */
  //           "@/views/miniGames/bugCatcher/PageBugCatcherBugCollection.vue"
  //         ),
  //       meta: {
  //         title: "Bug Collection",
  //       },
  //     },
  //   ],
  // },
  // // House Building Pages
  // {
  //   path: "/island/home/house",
  //   component: () =>
  //     import(/* webpackChunkName: "layout-house" */ "@/layouts/LayoutHome.vue"),
  //   beforeEnter: multiguard([loggedIn]),
  //   children: [
  //     {
  //       path: "wardrobe",
  //       name: "PageHouseWardrobe",
  //       component: () =>
  //         import(
  //           /* webpackChunkName: "house-Wardobe" */ "@/views/house/PageHouseWardrobe.vue"
  //         ),
  //       meta: {
  //         title: "Wardrobe",
  //       },
  //     },
  //     {
  //       path: "medals",
  //       name: "PageHouseMedals",
  //       component: () =>
  //         import(
  //           /* webpackChunkName: "house-medals" */ "@/views/house/PageHouseMedals.vue"
  //         ),
  //       meta: {
  //         title: "Medals",
  //       },
  //     },
  //   ],
  // },
  // // Garden Building Pages
  // {
  //   path: "/island/home/garden/:chapter",
  //   component: () => import("@/layouts/LayoutGarden.vue"),
  //   beforeEnter: multiguard([loggedIn]),
  //   children: [
  //     {
  //       path: "",
  //       name: "PageGardenChapter",
  //       component: () =>
  //         import("@/views/miniGames/garden/PageGardenChapter.vue"),
  //       meta: {
  //         title: "Garden",
  //       },
  //     },
  //     {
  //       path: ":key",
  //       name: "PageGardenQuestions",
  //       component: () =>
  //         import("@/views/miniGames/garden/PageGardenQuestions.vue"),
  //       meta: {
  //         title: "Garden",
  //       },
  //     },
  //   ],
  // },
  // // Events Building Pages
  // {
  //   path: "/events/event",
  //   component: () =>
  //     import(
  //       /* webpackChunkName: "layout-event" */ "@/layouts/LayoutEvent.vue"
  //     ),
  //   beforeEnter: multiguard([loggedIn]),
  //   children: [
  //     {
  //       path: "landing",
  //       name: "PageEventLanding",
  //       component: () =>
  //         import(
  //           /* webpackChunkName: "event-landing" */ "@/views/event/PageEventLanding.vue"
  //         ),
  //       meta: {
  //         title: "Event",
  //       },
  //     },
  //     {
  //       path: "home",
  //       name: "PageEventHome",
  //       component: () =>
  //         import(
  //           /* webpackChunkName: "event-home" */ "@/views/event/PageEventHome.vue"
  //         ),
  //       meta: {
  //         title: "Event",
  //       },
  //     },
  //     {
  //       path: "details",
  //       name: "PageEventLevelDetails",
  //       component: () =>
  //         import(
  //           /* webpackChunkName: "event-level-details" */ "@/views/event/PageEventLevelDetails.vue"
  //         ),
  //       meta: {
  //         title: "Event",
  //       },
  //     },
  //     {
  //       path: "tutorial",
  //       name: "PageEventTutorial",
  //       component: () =>
  //         import(
  //           /* webpackChunkName: "event-tutorial" */ "@/views/event/PageEventTutorial.vue"
  //         ),
  //       meta: {
  //         title: "Event",
  //       },
  //     },
  //     {
  //       path: "question",
  //       name: "PageEventQuestion",
  //       component: () =>
  //         import(
  //           /* webpackChunkName: "event-question" */ "@/views/event/PageEventQuestion.vue"
  //         ),
  //       meta: {
  //         title: "Event",
  //       },
  //     },
  //   ],
  // },

  // // to redirect if teacher not loged in
  // const loggedInTeacher = function (to, from, next) {
  //   if (!store.state.auth.data.Session)
  //     next({ name: "PageEntranceTeacherLogin" });
  //   next();
  // };
  // // Teacher Entrance Pages
  // {
  //   path: "/teacher",
  //   redirect: "/teacher/login",
  //   component: () =>
  //     import(
  //       /* webpackChunkName: "layout-entrance" */ "@/layouts/LayoutEntrance.vue"
  //     ),
  //   children: [
  //     {
  //       path: "login",
  //       name: "PageEntranceTeacherLogin",
  //       component: () =>
  //         import(
  //           /* webpackChunkName: "entrance-teacher-login" */ "@/views/entrance/PageEntranceTeacherLogin.vue"
  //         ),
  //       meta: {
  //         title: "Login",
  //       },
  //     },
  //     {
  //       path: "register",
  //       name: "PageEntranceTeacherRegister",
  //       component: () =>
  //         import(
  //           /* webpackChunkName: "entrance-teacher-register" */ "@/views/entrance/PageEntranceTeacherRegister.vue"
  //         ),
  //       meta: {
  //         title: "Register",
  //       },
  //     },
  //     {
  //       path: "school",
  //       beforeEnter: multiguard([loggedInTeacher]),
  //       name: "PageEntranceTeacherSchool",
  //       component: () =>
  //         import(
  //           /* webpackChunkName: "entrance-teacher-school" */ "@/views/entrance/PageEntranceTeacherSchool.vue"
  //         ),
  //       meta: {
  //         title: "Teacher",
  //       },
  //     },
  //     {
  //       path: "avatar/select",
  //       name: "PageEntranceTeacherAvatarSelect",
  //       component: () =>
  //         import(
  //           /* webpackChunkName: "entrance-teacher-avatar" */ "@/views/entrance/PageEntranceTeacherAvatarSelect.vue"
  //         ),
  //       meta: {
  //         title: "Select Avatar",
  //       },
  //     },
  //   ],
  // },
];

const router = new VueRouter({
  mode: "history",
  base: locale,
  routes,
});

//BOC:[page title]
router.afterEach((to) => {
  Vue.nextTick(() => {
    document.title = to.meta.title
      ? to.meta.title + " | Hamochi Kids"
      : "Hamochi Kids";
  });
});
//EOC

export default router;
